import { io } from "socket.io-client";

const serverPath = "https://mitserver.app:9005/";
const version = "V1.7";

const token = localStorage.getItem("token");
const user = JSON.parse(localStorage.getItem("user"));
const query = { version: version };

if (user != null) {
	query.userId = user.id;
}
if (token != null) {
	query.token = token;
}

const socket = io(serverPath, {
	transports: ["websocket"],
	reconnection: true,
	reconnectionAttempts: 5,
	reconnectionDelay: 2000,
	query: query,
});

const connectionStatus = document.getElementById("socketAPI");

socket.on("connect", () => {
	connectionStatus.style.backgroundColor = "green";
	// hideReconnectPopup();
});

socket.on("disconnect", (reason) => {
	connectionStatus.style.backgroundColor = "red";
	// showReconnectPopup();
});

socket.on("close", (reason) => {
	connectionStatus.style.backgroundColor = "red";
	// showReconnectPopup();
});

socket.on("error", (error) => {
	connectionStatus.style.backgroundColor = "red";
	// showReconnectPopup();
});

document.addEventListener("visibilitychange", async () => {
	const selectedAccount = await JSON.parse(localStorage.getItem("selectedAccount"));
	const symbolInfo = await JSON.parse(localStorage.getItem("symbolInfo"));
	const token = await localStorage.getItem("token");

	if (symbolInfo && selectedAccount) {
		let allPos = {
			symbol: symbolInfo.symbol,
			userId: selectedAccount.userId,
			account: selectedAccount.account,
		};
		socket.emit("allLines", allPos);
	}
	// console.table({
	// 	socketConnected: socket.connected,
	// 	hasSelectedAccount: !!selectedAccount,
	// 	hasToken: !!token,
	// });
	if (!socket.connected && selectedAccount && token) {
		socket.io.opts.query = {
			version: version,
			userId: selectedAccount.userId,
			token: token,
		};
		socket.connect();
	}
});

export default socket;
