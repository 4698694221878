import React from "react";
import Lottie from "lottie-react";
import animationData from "./animations/candles.json"; // Reemplaza con la ruta a tu archivo JSON
import "./lottieLoader.css";

const LottieLoader = () => {
	return (
		<div className="lottie-loader-container">
			<Lottie animationData={animationData} loop={true} autoplay={true} />
		</div>
	);
};

export default LottieLoader;
